<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            {{ isBookingCenter() ? "登記預約中心" : "預約管理" }}
          </b-breadcrumb-item>

          <b-breadcrumb-item v-if="bookingPreset.name" :to="isBookingCenter() ? { name: 'BookingCenterBookingList', params: { id: bookingPreset.id }} : { name: 'BookingList', params: { id: bookingPreset.id }}">
            {{ bookingPreset.name }}
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯預約' : '新增預約'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-12">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- begin: dynamic form -->
              <div>
                <h4 class="mb-2 mb-xl-0 font-weight-bold">{{ isEditing ? "編輯預約" : "新增預約" }} - {{ bookingPreset.name }}</h4>
                <b-form-group v-bind:label="'預約會員'" label-cols-sm="3" label-cols-lg="2" content-cols-lg="9" style="width: 100%">
                  <div v-if="customer" class="d-flex align-items-center py-2 staff-info">
                    <b-avatar
                      class="mx-2"
                      :src="customer.avatar_url"
                    ></b-avatar>
                    <div
                      class="
                        mr-auto
                        font-weight-bold
                        text-nowrap text-truncate
                      "
                    >
                      {{ customer.name }}
                      <span class="staff-list-info">
                        {{ customer.email }}<br>
                        {{ displayCustomerInfo(customer) }}
                      </span>
                    </div>
                  </div>
                  <StaffAddCustomer
                    ref="staffAddCustomer"
                    :add-button-text="addCustomerButtonText"
                    @bind="handleSelectCustomer"
                    :sourceType="'line'"
                  ></StaffAddCustomer>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="開始時間"
                  label-for="start_at"
                  v-if="false === hasSetUpFieldMappingForStartAt"
                >
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <datepicker
                        placeholder="Select Date"
                        v-model="start_at.date"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :input-class="deepGet(v$, 'start_at.date.$error') ? 'is-invalid' : ''"
                      ></datepicker>
                      <b-form-invalid-feedback :state="!deepGet(v$, 'start_at.date.$error')">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>

                    <div class="col-12 col-xl-6">
                      <vue-timepicker
                        format="HH:mm"
                        v-model="start_at.time"
                        :input-class="[
                          'form-control'
                        ]"
                        :style="deepGet(v$, 'start_at.time.HH.$error') || deepGet(v$, 'start_at.time.mm.$error') ? 'border: 1px solid red' : ''"
                      ></vue-timepicker>
                      <b-form-invalid-feedback :state="!deepGet(v$, 'start_at.time.HH.$error') || !deepGet(v$, 'start_at.time.mm.$error')">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="結束時間"
                  label-for="end_at"
                  v-if="false === hasSetUpFieldMappingForEndAt"
                >
                  <div class="row">
                    <div class="col-12 col-xl-6">
                      <datepicker
                        placeholder="Select Date"
                        v-model="end_at.date"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :input-class="deepGet(v$, 'end_at.date.$error') ? 'is-invalid' : ''"
                      ></datepicker>
                      <b-form-invalid-feedback :state="!deepGet(v$, 'end_at.date.$error')">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>

                    <div class="col-12 col-xl-6">
                      <vue-timepicker
                        format="HH:mm"
                        v-model="end_at.time"
                        :input-class="[
                          'form-control'
                        ]"
                        :style="deepGet(v$, 'end_at.time.HH.$error') || deepGet(v$, 'end_at.time.mm.$error') ? 'border: 1px solid red' : ''"
                      ></vue-timepicker>
                      <b-form-invalid-feedback :state="!deepGet(v$, 'end_at.time.HH.$error') || !deepGet(v$, 'end_at.time.mm.$error')">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-form-group>
                <DynamicFormDashboard
                  ref="dynamicForm"
                  :input-subjects="bookingPreset.config.fields"
                  v-model="inputForm"
                ></DynamicFormDashboard>
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center mt-4">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </div>
              <!-- end: dynamic form -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import bookingApi from "@/apis/booking";
// import DynamicForm from "@/components/DynamicForm/DynamicForm"
import DynamicFormDashboard from "@/components/DynamicForm/DynamicFormDashboard"
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";
import {format, getHours, getMinutes, set} from "date-fns";
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { mapGetters } from "vuex";

export default {
  components: {
    // DynamicForm,
    DynamicFormDashboard,
    StaffAddCustomer,
    Datepicker,
    VueTimepicker,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      start_at: this.hasSetUpFieldMappingForStartAt ? {} : {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
      end_at: this.hasSetUpFieldMappingForEndAt ? {} : {
        date: { required },
        time: {
          HH: { required },
          mm: { required },
        },
      },
    };
  },
  data() {
    return {
      consts,
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: '',
      bookingPreset: {
        name: null,
        config: {
          fields: [],
        }
      },
      start_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      end_at: {
        date: null,
        time: {
          HH: null,
          mm: null,
        },
      },
      inputForm: {},
      booking: {
        customer_id: null,
        start_at: null,
        end_at: null,
        data: []
      },
      customer: null,
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("route", ["routeQuery", "routeParams"]),
    addCustomerButtonText() {
      return this.customer ? "重新選擇會員" : "選擇此預約所屬會員";
    },
    hasSetUpFieldMappingForStartAt() {
      return _.get(this.bookingPreset, 'config.booking_mapping.bookings.start_at', null) !== null;
    },
    hasSetUpFieldMappingForEndAt() {
      return _.get(this.bookingPreset, 'config.booking_mapping.bookings.end_at', null) !== null;
    },
  },
  methods: {
    deepGet: _.get,
    isBookingCenter() {
      return this.$route.name.includes("BookingCenter");
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async init () {
      this.bookingPreset.id = this.$route.params.id;
      await this.fetchBookingPreset();
      if (["BookingEdit", "BookingCenterBookingEdit"].includes(this.$route.name)) {
        this.isEditing = true;
        this.booking.id = this.$route.params.booking_id;
        await this.fetchBooking();
      } else {
        this.isEditing = false;
      }
    },
    cancel() {
      if (this.isBookingCenter()) {
        this.$router.push({ name: 'BookingCenterBookingList', params: {id: this.bookingPreset.id} });
      } else {
        this.$router.push({ name: 'BookingList', params: this.routeParams, query: this.routeQuery});
      }
    },
    async submit() {
      // 先驗證 vuelidate 的字段
      const isVuelidateValid = await this.v$.$validate();

      // 如果 vuelidate 驗證不通過，退出函數並顯示錯誤
      if (!isVuelidateValid) {
        return;
      }

      const validate = await this.$refs.dynamicForm.validate();
      if (!validate) return;

      try {
        this.showLoading = true;

        this.formatDatetime("start_at");
        this.formatDatetime("end_at");

        // 將 item.data 組起來
        let data = [];

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦

          data.push({
            field_id: fieldId,
            title: field.title,
            value: this.inputForm[fieldId] ?? null,
          });
        }

        const bookingDataToSave = {
          data,
          customer_id: this.customer ? this.customer.id : null,
        };

        if (false === this.hasSetUpFieldMappingForStartAt) {
          bookingDataToSave.start_at = this.booking.start_at;
        }

        if (false === this.hasSetUpFieldMappingForEndAt) {
          bookingDataToSave.end_at = this.booking.end_at;
        }

        // 編輯表單
        if (this.isEditing) {
          await bookingApi.updateBooking(this.bookingPreset.id, this.booking.id, bookingDataToSave);
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          await bookingApi.createBooking(this.bookingPreset.id, bookingDataToSave);
          this.$swal("新增成功", "", "success");
          this.cancel();
        }
        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookingPreset() {
      if (! this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);

        if (! response.data.data.config) {
          response.data.data.config = {
            fields: [],
          };
        }

        this.bookingPreset = response.data.data;

        if (!this.bookingPreset.config?.fields || this.bookingPreset.config.fields.length === 0) {
          this.$swal("提醒", "此預約尚未設定欄位，須設定欄位後才能新增及編輯預約", "warning");
          this.cancel();
          return;
        }

        this.bookingPreset.config.fields = this.bookingPreset.config.fields.map((field) => {
          return {
            id: field._id ?? field.config._id, // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
            ...field,
          }
        });

        let editInputForm = {};

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          editInputForm[fieldId] = null;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBooking() {
      try {
        this.showLoading = true;
        const response = await bookingApi.getBooking(this.bookingPreset.id, this.booking.id);
        this.booking = response.data;

        let editInputForm = {};

        for (let i = 0; i < this.bookingPreset.config.fields.length; i ++) {
          const field = this.bookingPreset.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          const fieldData = this.booking.data.find((data) => data.field_id === fieldId)?.value ?? null;

          if (field.type === "checkbox") {
            editInputForm[fieldId] = Array.isArray(fieldData) ? fieldData : [fieldData].filter(Boolean);
          } else {
            editInputForm[fieldId] = fieldData;
          }
        }

        const { start_at, end_at } = this.booking;
        if (start_at) {
          this.start_at = {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          };
        }
        if (end_at) {
          this.end_at = {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          };
        }
        this.customer = this.booking.customer;

        this.inputForm = editInputForm;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async handleSelectCustomer(customer) {
      this.$swal({
        title: "確定要加入會員嗎",
        html: `
          <div class="d-block">
            <div class="my-3 text-left">
              <div>會員姓名:${customer.name}</div>
            </div>
          </div>`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "返回",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "確認加入",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.customer = customer;
          } catch (e) {
            console.error(e)
          } finally {
            this.$refs.staffAddCustomer.refreshSearch()
            this.$refs.staffAddCustomer.closeModal()
          }
        }
      })
    },
    displayCustomerInfo(customer) {
      return ((customer.outer_code) ? customer.outer_code + " " : "") + (customer.mobile_phone ?? "");
    },
    formatDatetime(key) {
      if (!this[key].date || !this[key].time.HH || !this[key].time.mm) return;

      this.booking[key] = format(
        set(new Date(this[key].date), {
          hours: Number(this[key].time.HH),
          minutes: Number(this[key].time.mm),
          seconds: 0,
        }),
        "yyyy-MM-dd HH:mm:ss"
      );
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
